@import "./custom-variable1";
@import "./main1";

/* my profile */
.Pricing-wrapper {
  .Pricing {
    padding: $base-padding 0px $base-padding 0px;
    @include respond-to("desktop-lg") {
      padding: 30px 0px 30px 0px;
    }
    @include respond-to("desktop-md") {
      padding: $padding-md-7 0px $padding-md-7 0px;
    }
    .Subscription-head {
      text-align: center;
      padding-bottom: $base-padding;
      @include respond-to("desktop-lg") {
        padding-bottom: 30px;
      }
      h2 {
        @include respond-to("desktop-md") {
          margin-bottom: 0px;
          line-height: 40px;
        }
      }
    }
    .Subscription-Plan {
      display: flex;
      align-items: center;
      margin: 0px auto;
      justify-content: center;
      @include respond-to("desktop-md") {
        display: block;
      }

      .Subscription-details {
        background: linear-gradient(144.36deg, #185b9b 0.76%, #89189b 97.25%);
        box-shadow: 0px 0px 15px rgba(74, 74, 74, 0.1);
        border-radius: $border-radius-base;
        padding: $base-extra-small-padding;
        min-width: 273px;
        margin-right: 56px;
        position: relative;
        @include respond-to("desktop-md") {
          min-width: 100%;
          margin-right: 0px;
          margin-bottom: 15px;
        }
        .free-trial {
          position: absolute;
          top: -14px;
          transform: translateX(-50%);
          left: 50%;
          button {
            background: $color-secondary;
            border-radius: $border-radius-medium;
            color: $color-white;
            width: 136px;
            height: 36px;
          }
        }
        .time-info {
          position: relative;
          top: -25px;
          padding-bottom: 25px;
          h1,
          span,
          small {
            color: $color-white;
          }
          h1 {
            padding-left: 15px;
            span {
              font-size: $font-heading-h5;
            }
          }
          small {
            font-size: $font-large;
            top: 25px;
            left: 0px;
            position: relative;
            font-weight: $font-weight-s;
          }
        }
        .Business-plan {
          p {
            font-size: $font-large;
            color: $color-white;
          }
          span {
            font-size: $font-extra-small;
            margin-bottom: 15px;
            color: #4488c9;
            display: inline-block;
          }
        }
        ul {
          li {
            color: $color-dark-gray;
            font-size: $font-small;
            padding-bottom: 12px;

            img {
              margin-right: 5px;
            }
          }
        }
        .plan-expire {
          padding: $margin-4 0px $margin-4 0px;
          margin: 0px;
          color: $color-white;
          font-weight: $font-weight-b;
          font-size: 13px;
        }
        .Cancel-Plan {
          text-align: center;
          div {
            cursor: pointer;
            font-size: $font-small;
            color: #9c6dff;
            text-decoration: underline;
          }
        }
      }
      .Subscription-details.two {
        background: linear-gradient(144.36deg, #185b9b 0.76%, #89189b 97.25%);
        box-shadow: 0px 0px 15px rgba(74, 74, 74, 0.1);
      }
    }
  }
}
