@import "./custom-variable1";
@import "./main1";

/* my profile */
.My-Profile-wrapper {
  // padding-top: $base-ml-padding;
  .My-Profile {
    width: 323px;
    margin: 0 auto;
    text-align: center;
    padding-bottom: $base-extra-small-padding;
    .profile-img {
      .profile {
        display: inline;
        position: relative;
        .img-pro {
          width: 96px;
          height: 96px;
        }
        .edit-profile {
          position: absolute;
          right: 1px;
          top: 35px;
          cursor: pointer;
        }
        .Edit {
          color: $color-secondary;
          font-size: $font-extra-small;
          cursor: pointer;
          padding-top: 6px;
        }
      }
    }
    .form-information {
      padding: $base-extra-small-padding;
      padding-bottom: 2px;
      background: $color-white;
      box-shadow: 0px 0px 15px rgba(74, 74, 74, 0.1);
      border-radius: 14px;
      text-align: left;
      span {
        color: $placeholder;
        font-size: $font-extra-small;
      }
      p {
        font-size: $font-small;
      }
      .information {
        padding-bottom: 15px;
      }
    }
  }
  .upadte-edit-btn {
    padding-top: 37px;
  }
  .download-button {
    padding: $base-extra-small-padding 0px 0px 0px;
    button {
      background: transparent;
      border: 1px solid $color-secondary;
      color: $color-black;
      box-sizing: border-box;
      filter: drop-shadow(0px 0px 15px rgba(74, 74, 74, 0.1));
      border-radius: $border-radius-base;
      img {
        margin-left: $margin-4;
      }
    }
  }
}
