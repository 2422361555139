@import "./custom-variable1";
@import "./main1";

.sign-in-container{
  
//.....................signup styles......................//

.sign-in-wrapper {
  background-image: url(../assets/images/login-background.png);
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  background-position: center;
  z-index: 1;
  text-align: right;
  display: inline-block;
  .signin-main {
    position: relative;
    padding-bottom: 80px;
    text-align: right;
    display: inline-block;
    .signin-box {
      background: $color-light-gray;
      width: 484px;
      position: relative;
      right: 96px;
      top: 43px;
      border-radius: $border-radius-base;
      padding: $base-padding $base-larg-padding $base-medium-padding
        $base-larg-padding;
      @include respond-to("desktop-lg") {
        padding: 30px 70px 30px 70px;
      }
      .logo-img {
        padding-bottom: 15px;
        text-align: center;
      }
      h3 {
        padding-bottom: $base-medium-padding;
        justify-content: center;
        text-align: center;
      }
      .Sign-In-button {
        text-align: center;
        button {
          margin-top: 20px;
        }
        .Skip,
        .forget-Password {
          margin-top: $margin-5;
          background: transparent;
          font-size: $font-extra-small;
          line-height: $line-height-extra-small;
          color: $placeholder;
          height: fit-content;
          cursor: pointer;
        }
      }
      .setup-new-pass {
        .form-group {
          position: relative;
          i {
            position: absolute;
            top: 12px;
            right: 35px;
            @include respond-to("desktop-lg") {
              top: 14px;
            }
            @include respond-to("desktop-lg") {
              top: 11px;
            }
          }
        }
      }
    }
  }
}

//..................Setup-profile......................//
.sign-in-wrapper.setup-profile {
  text-align: right;
  display: inline-block;
  .signin-box {
    padding: $base-medium-padding $base-larg-padding $base-medium-padding
      $base-larg-padding;
    h3 {
      padding: 0px;
    }
    .tabbable-panel {
      .nav.nav-tabs {
        justify-content: space-around;
        margin: $base-small-margin 0 $base-small-margin 0;
        background: $color-white;
        display: flex;
        align-items: center;
        border: none;
        border-radius: $border-radius-base;
        li {
          a {
            font-size: $font-small;
            line-height: $line-height-small;
            color: $color-gray;
            text-decoration: none;
          }
        }
        li.active {
          background: #2786e2;
          border-radius: $border-radius-base;
          padding: $padding;
          margin: 0px -11px 0px -11px;
          a {
            color: $color-white;
          }
        }
      }
    }
    .profile-img {
      padding-bottom: $padding-4;
      text-align: center;
      justify-content: center;
    }
  }
}
}


