@import "./custom-variable1";
// @import "./main";

.home-top-wrapper {
  position: relative;

  .home-wrapper {
    background-image: url(../assets/images/home-banner.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba(30, 30, 30, 0.3);
    position: relative;
    height: 460px;
    @include respond-to("desktop-lg") {
      height: 350px;
    }
    @include respond-to("desktop-md") {
      height: 300px;
    }
    @include respond-to("desktop-xs") {
      height: 200px;
    }
    .home-bg-text {
      float: right;
      position: relative;
      top: 172px;
      backdrop-filter: blur(5px);
      @include respond-to("desktop-lg") {
        top: 110px;
      }
      @include respond-to("desktop-md") {
        top: 80px;
      }
      @include respond-to("desktop-xs") {
        top: 35px;
        backdrop-filter: blur(0px);
      }
      h1 {
        color: $color-black;
        @include respond-to("desktop-sm") {
          font-size: 25px;
          img {
            width: 120px;
          }
        }
        @include respond-to("desktop-xs") {
          color: $color-white;
          font-size: 24px;
        }
        img {
          position: relative;
          top: -4px;
        }
      }
      p {
        color: $color-black;
        @include respond-to("desktop-md") {
          text-align: justify;
        }

        @include respond-to("desktop-xs") {
          color: $color-white;
        }
      }
    }
  }
  .What-we-do {
    display: flex;
    align-items: center;
    padding: 117px 0px 117px 0px;
    @include respond-to("desktop-lg") {
      padding: 50px 0px 50px 0px;
    }
    @include respond-to("desktop-md") {
      padding: 15px 0px 15px 0px;
    }
    @include respond-to("desktop-sm") {
      display: block;
    }
    @include respond-to("desktop-xs") {
      display: block;
    }
    .what-we-do-img {
      text-align: left;
      @include respond-to("desktop-sm") {
        text-align: center;
        justify-content: center;
      }
      img {
        width: 417px;
        @include respond-to("desktop-lg") {
          width: 370px;
        }
        @include respond-to("desktop-md") {
          width: 320px;
        }
        @include respond-to("desktop-sm") {
          width: auto;
        }
        @include respond-to("desktop-xs") {
          width: 100%;
        }
      }
    }
    .what-we-do-text {
      padding-left: 163px;
      @include respond-to("desktop-lg") {
        padding-left: 80px;
      }
      @include respond-to("desktop-md") {
        padding-left: 30px;
      }
      @include respond-to("desktop-sm") {
        padding-left: 0px;
      }
      h2 {
        font-weight: $font-weight-s;
        margin-bottom: 0px;
      }
      p {
        padding-top: 19px;
        @include respond-to("desktop-sm") {
          text-align: justify;
        }
      }
    }
  }
  .how-it-work{

    padding-top: 0px;
  }
  .Who-we-are {
    .what-we-do-text {
      padding-right: 100px;
      @include respond-to("desktop-lg") {
        padding-right: 70px;
      }
      @include respond-to("desktop-md") {
        padding-right: 30px;
      }
      @include respond-to("desktop-xs") {
        padding-right: 0px;
      }
      ul li {
        list-style: disc;
      }
    }
  }
  .Unique-Features-wrapper {
    padding: 56px 0 56px 0;
    @include respond-to("desktop-lg") {
      padding: 30px 0 30px 0;
    }
    @include respond-to("desktop-md") {
      padding: 30px 0 30px 0;
    }
    @include respond-to("desktop-xs") {
      padding: 20px 0 20px 0;
    }
    .Unique-Features {
      .Unique-head {
        margin: 0px auto;
        text-align: center;
        padding-bottom: 60px;
        @include respond-to("desktop-lg") {
          padding-bottom: 30px;
        }
        @include respond-to("desktop-md") {
          padding-bottom: 30px;
        }
        @include respond-to("desktop-xs") {
          padding-bottom: 20px;
        }
        h2 {
          font-weight: $font-weight-s;
          text-align: center;
          margin-bottom: 0px;
          @include respond-to("desktop-xs") {
            text-align: left;
          }
        }
      }
      .Unique-box {
        display: flex;
        align-items: center;
        justify-content: center;
        @include respond-to("desktop-md") {
          display: block;
        }
        .comman-box {
          border: 1px solid orange;
          padding: $base-extra-small-padding;
          background: $color-white;
          width: 364px;
          text-align: center;
          border-radius: $border-radius-base;
          margin-right: $base-medium-margin;
          @include respond-to("desktop-md") {
            padding: $base-medium-padding-lg;
          }
          @include respond-to("desktop-md") {
            margin-right: 0px;
            justify-content: center;
            text-align: center;
            display: block;
            margin: 0 auto;
            margin-bottom: $base-extra-small-margin;
            padding: $base-extra-small-padding-lg;
          }
          @include respond-to("desktop-xs") {
            width: auto;
          }

          h3 {
            padding-top: $base-extra-small-padding;
            padding-bottom: $base-extra-small-padding;
            font-size: 24px;
            font-weight: $font-weight-b;
            line-height: 36px;
            @include respond-to("desktop-lg") {
              padding-top: $padding-6;
              padding-bottom: $padding-6;
            }
          }
          p {
            color: $color-gray;
          }
        }
        .feedback.comman-box {
          background: $color-white;
          h3 {
            // color: $color-white;
          }
          p {
            // color: #b9b6b6;
          }
        }
        .comman-box:last-child {
          margin-right: 0px;
          @include respond-to("desktop-md") {
            margin-right: auto;
          }
        }
      }
    }
  }
}
