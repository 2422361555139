/* *{
    display: none;
} */

.cbadge-expired{
    width:4rem !important;
    height: 1.1rem !important;
    background-color:red !important;
}
.cbadge-pending{
    width:4rem !important;
    height: 1.1rem !important;
    background-color:yellow !important;
}

.cbadge-reassigned{
    width:5rem !important;
    height: 1.1rem !important;
    background-color:#f9b115 !important;
}

.cbadge-cancelled{
    width:4rem !important;
    height: 1.1rem !important;
    background-color:grey !important;
}

.cbadge-system{
    width:4rem !important;
    height: 1.1rem !important;
    background-color:red !important;  
}

.cbadge-admin{
    width:4rem !important;
    height: 1.1rem !important;
    background-color:grey !important;  
}

.cbadge-employee{
    width:4rem !important;
    height: 1.1rem !important;
    background-color:green !important;  
}