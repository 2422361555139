// Here you can add other styles

.cellclass {
  font-family: "Poppins";
}

.table-class {
  text-align: center;
}

.newtable-class {
  text-align: center;
  // width: 50rem;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.catTableItem {
  //font-size: 2rem;
  font-family: "Poppins", sans-serif;

  font: bolder;
}

.email-validate {
  color: red;
  margin-bottom: 1rem;
  font-size: 12px;

}

.message-customstyle {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 2rem;

  .scrollbar-container {
    all: revert;
    overscroll-behavior-y: none;
    overflow-anchor: auto;
    touch-action: none;
    position: relative;
    //margin-right: 2rem;
  }
  // .scrollbar-container {
  //   position: relative;
  // }
}

// .scrollbar-container {
//   min-height: 2rem !important;
// }



  //  .updatebutton{
  //   margin-top: 50px !important
  // } 