@import "./custom-variable";
// @import "./main";
/* chat style */
.chat-details-wrapper {
  padding-top: 0px;
  padding-bottom: 0px;
  min-height: 330px;
  .message-chat-details {
    padding-top: $base-ml-padding;
    padding-bottom: 0px;
  }
  .chat-details {
    background: $color-white;
    box-shadow: 0px 0px 15px rgba(83, 83, 83, 0.1);
    border-radius: $border-radius-base;
    padding: 12px;
    margin-bottom: 12px !important;
    .media {
      display: flex;
      align-items: center;
      .sb-avatar.sb-avatar--text {
        margin: 0px 16px 0px 0px !important;
      }
      h5 {
        font-weight: $font-weight-s;
      }
      p {
        font-weight: $font-weight-s;
        font-size: $font-extra-small;
        line-height: 1;
        color: $color-gray;
      }
      .color-pink {
        color: $color-secondary;
      }
    }
    .socil-img {
      text-align: right;
      p {
        color: $color-gray;
        font-size: $font-extra-small;
      }
      img {
        padding-right: 15px;
        cursor: pointer;
      }
      img:last-child {
        padding-right: 0px;
      }
      .color-pink-time {
        color: $color-secondary;
        font-size: $font-extra-small;
      }
    }
  }
  .chat-details:last-child {
    margin-bottom: 0px !important;
  }
  .chatthread {
    padding: 45px !important;
  }
}

/* chat detail style */
.inbox_msg {
  clear: both;
  overflow: hidden;
}
.incoming_msg_img {
  display: inline-block;
}
.received_msg {
  display: inline-block;
  padding: 0 0 0 7px;
  vertical-align: top;
  width: 92%;
}
.received_withd_msg p {
  background: $color-white;
  border-radius: $border-radius-base $border-radius-base $border-radius-base 0px;
  box-shadow: 0px 0px 15px rgba(83, 83, 83, 0.1);
  margin: 0;
  color: $placeholder;
  font-size: $font-extra-small;
  padding: 5px $padding-6 5px 12px;
  display: inline-block;
  position: relative;
  .moji-wrap {
    width: auto;
    position: absolute;
    right: 4px;
    bottom: -6px;
    height: 15px;
  }
}
.sent_msg p {
  background: $color-white;
  border-radius: $border-radius-base $border-radius-base 0px $border-radius-base;
  box-shadow: 0px 0px 15px rgba(83, 83, 83, 0.1);
  margin: 0;
  color: $placeholder;
  font-size: $font-extra-small;
  padding: 5px $padding-6 5px 12px;
  display: inline-block;
  position: relative;
  .moji-wrap {
    width: auto;
    position: absolute;
    right: 4px;
    bottom: -6px;
    height: 15px;
  }
}
.received_withd_msg {
  width: 57%;
  position: relative;
}
.mesgs {
  float: left;
  padding: 0px;
  width: 100%;
}
.time_date {
  color: #747474;
  display: block;
  font-size: 7px;
  margin: 8px 0 0;
}

.outgoing_msg,
.incoming_msg {
  overflow: hidden;
  margin: 6px 0 6px;
  position: relative;
  img,
  .sb-avatar.sb-avatar--text {
    float: right;
    width: 36px;
    display: flex;
    margin-left: $margin-2;
    height: 36px;
    border-radius: 100%;
  }
}
.incoming_msg-wrap {
  position: relative;
  .mojis-img-wrapper {
    position: absolute;
    background-image: url(../assets/images/moji-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 8px;
    width: auto;
    top: -50px;
    left: 72px;
    border-radius: 16px;
    img {
      padding-right: 8px !important;
      cursor: pointer;
    }
    .sign-down {
      img {
        position: absolute;
        bottom: -7px;
        left: 49%;
        transform: translateX(-50%);
      }
    }
  }
  .mojis-will-open {
    position: absolute;
    right: -18px;
    top: 0px;
    cursor: pointer;
    img {
      width: auto;
      height: 15px;
    }
  }
}

.sent_msg {
  float: right;
  width: 51%;
  text-align: right;
}
.input_msg_write input {
  border:none !important;
  height: 31px !important;
  width: 100%;
  outline: none;
  padding: 0 60px 0 $padding-4;
  font-size: $font-extra-small;
  background: $color-light-gray-bg !important;
  margin: 10px 0;
  border-radius: 2rem;
}

.type_msg {
  position: relative;
}
.send-icon {
  position: absolute;
  right: 24px;
  top: 6px;
  width: auto;
  .file-uplod {
    padding-right: $base-padding;
    cursor: pointer;
    // @include respond-to("desktop-lg") {
    //   padding-right: $base-padding-lg;
    // }
  }
  .msg_send_btn {
    background: transparent;
    border: medium none;
    cursor: pointer;
    box-shadow: none;
    float: right;
    text-align: right;
    position: relative;
    top: 7px;
    right: -15px;
    // @include respond-to("desktop-lg") {
    //   top: 0px;
    // }
    // @include respond-to("desktop-md") {
    //   top: 1px;
    // }
  }
}

.msg_history {
  height: calc(100vh - 252px);
  // overflow-y: auto;
  padding-bottom: 0px;
  position: relative;
  .msgsc {
    position: relative;
    height: calc(100vh - 252px) !important;
    overflow-y: auto;
  }
  .msg-dy-wrap {
    position: relative;
  }
  .msg-day {
    text-align: center;
    p {
      font-size: 10px;
      color: #a2a5a1;
      padding-bottom: 8px;
    }
    span {
      background: $color-white;
      box-shadow: 0px 4px 15px rgba(83, 83, 83, 0.1);
      border-radius: 6px;
      display: inline-block;
      font-size: $font-small;
      width: 166px;
      padding: 4px;
      margin-bottom: $base-ml-margin;
    }
  }
  .msg-day p::before {
    content: "";
    background: #cbcbcb;
    width: 47%;
    height: 1px;
    position: absolute;
    left: 0;
    top: 14px;
  }
  .msg-day p::after {
    content: "";
    background: #cbcbcb;
    width: 47%;
    height: 1px;
    position: absolute;
    right: 0;
    top: 14px;
  }
}

//------------calling----------------//
.hospital-info-main-wrapper {
  .calling-wrapper {
    .calling-head {
      background: $color-secondary;
      padding: $padding-8 $base-padding $padding-8 $base-padding;
      .caller-name {
        color: white;
      }
      .Exit {
        float: right;
        cursor: pointer;
        p {
          color: white;
          font-weight: $font-weight-m;
        }
      }
    }
    .calling-body {
      // background: $Lignt-Blue-tabs;
      background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.08) 4.79%,
          rgba(17, 17, 17, 0.53) 86.48%
        ),
        url(../assets/images/clint1.png);
      height: calc(100vh - 192px);
      position: relative;
      background-size: cover;
      .connect-to {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        text-align: center;
        img {
          padding-bottom: 16px;
        }
        h5,
        p {
          color: white !important;
        }
      }
      .call-options {
        position: absolute;
        bottom: 24px;
        left: 50%;
        transform: translateX(-50%);
        img {
          padding-right: 20px;
          cursor: pointer;
        }
      }
      .call-duration {
        background: #ffffff6b;
        float: right;
        margin-right: $base-margin;
        margin-top: $base-margin;
        width: 80px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-secondary;
        font-weight: $font-weight-m;
        border-radius: 16px;
        span {
          color: white;
          font-size: 14px;
        }
      }
      .client-video {
        img {
          position: absolute;
          right: 24px;
          bottom: 24px;
        }
      }
    }
  }
}

.Notification-main-wrap {
  width: 638px;
  background: $color-white;
  box-shadow: 0px 0px 15px rgba(83, 83, 83, 0.1);
  border-radius: 14px;
  margin: 0px auto;
  padding: 43px 70px;
  // height: 538px !important;
  // overflow-y: auto;
  .Notification-head {
    h2 {
      font-weight: $font-weight-b;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      text-decoration-line: underline;
      color: $placeholder;
      padding-bottom: 36px;
      margin: 0px;
    }
  }
  .Notification-body {
    height: 450px !important;
    overflow-y: auto;
    .Notification-chat {
      border: 1px solid #ed8423;
      box-sizing: border-box;
      border-radius: $border-radius-medium;
      padding: 18px 24px 18px 12px;
      margin-bottom: $margin-8;
      h5,
      p {
        color: $placeholder;
      }
    }
    .Notification-chat:last-child {
      margin-bottom: 0px;
    }
  }
}

.notif-wrapper {
  padding: 0px $base-extra-small-padding 10px $base-extra-small-padding;
  // padding: 34px -135px 39px 23px;
  width: 523px;
  background: none;
  box-shadow: none;
  border-radius: 8px;
  margin: 0 auto;
  margin-bottom: 57px;
  position: absolute;
  img.cross {
    position: relative;
    top: 20px;
    left: 611px;
  }
  .team-member-head {
    text-align: center;
    padding-bottom: 32px;
  }
  .select-team-member {
    height: calc(100vh - 252px) !important;
    overflow-y: auto;
    ul {
      margin-bottom: 0px;
      li {
        display: flex;
        align-items: center;
        padding-bottom: 14px;
        cursor: pointer;
        span {
          font-size: 14px;
        }
        img {
          margin-right: $margin-4;
        }
      }
    }
  }
}
.modal-dialog.notifmodal {
  height: 410px;
  background: none;
  position: absolute;
  left: 42%;
  top: 30%;
  transform: translateX(-50%) translateY(-50%) !important;
  .modal-content {
    background: transparent;
    border: 0;
  }
  .modal-body {
    padding: 0;
  }
}
.modal.notif {
  .modal-dialog {
    .modal-content {
      .modal-header {
        background: #ff6b34;
        border: none;

        p {
          color: white;
        }
        .close {
          width: 37px !important;
        }
      }
    }
  }
}
