.errmsg {
    width: 100%;
      margin-top: 0.25rem;
      font-size: 80%;
      color: #dc3545;
  }

  button.Toastify__close-button.Toastify__close-button--error {
    /* padding-left: 180px; */
    /* text-align: right; */
    width: 21%;
  }
  button.Toastify__close-button.Toastify__close-button--success {
    /* padding-left: 180px; */
    text-align: right;
    width: 21%;
    background: #ff7500 !important;
    box-shadow: none !important;
  }
  .Toastify__toast--success {
    background: #ff7500 !important;
    white-space: pre-line;
    position: relative;
  }
  .Toastify__toast-body {
    width: -webkit-fill-available;
  }

  .ratingerrmsg {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
    text-align: -webkit-auto;
    position: absolute;
    bottom: -20px;
  }
  .textmsg {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: gray;
    padding-top: 7px;
  }
  .sucessmsg {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: rgb(7, 167, 42);
    text-align: -webkit-center;
  }
  .teammsg {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 135%;
    color: #ff622e;
    text-align: center;
    margin-bottom: 10%;
  }
  
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #094e94;
  }
  
  .custom-control-label::before {
    color: #5e6063;
    background-color: #fff;
  }
  .textwrap {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .goalButton {
    background: transparent;
    box-shadow: 0px 0px 15px rgba(74, 74, 74, 0.1);
    box-sizing: border-box;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    height: 50px;
    width: 100%;
    color: #000000 !important;
    margin: 0px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 0px;
    padding-left: 16px;
    border: 1px solid #ff7500;
  }
  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
    display: flex;
    justify-content: center;
  }
  
  h5.modal-title {
    width: 1456px;
  }
  
  .input-container input {
    border: none;
    box-sizing: border-box;
    outline: 0;
    padding: 0.75rem;
    position: relative;
    width: 100%;
  }
  
  input[type="date"].goalcalendar::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
  input:focus {
    outline: none !important;
    border: none;
    /* box-shadow: 0 0 10px #719ECE; */
  }
  .ChatContent {
    overflow-y: scroll;
    height: 600px;
  }
  