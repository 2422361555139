

@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap");
@import "./custom-variable1";
@import "./mixins";


// Globle css //
// * {
//   box-sizing: border-box;
//   margin: 0;
//   padding: 0;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

.home-container{

  hr {
    margin: 0;
    padding: 0;
  }
  
 
  a {
    font-weight: $font-weight-s;
    font-size: $font-large;
    line-height: $line-height-large;
    color: $color-white;
    cursor: pointer;
    @include respond-to("desktop-lg") {
      font-size: $font-large-lg;
    }
    @include respond-to("desktop-md") {
      font-size: $font-large-md;
    }
  }
  a:hover {
    color: $color-white;
  }
  
  h1,
  h3,
  h3,
  h4,
  h5 {
    font-weight: $font-weight-m;
    margin: 0;
    letter-spacing: $base-letter-spacing;
  }
  
  h1 {
    font-size: $font-heading-h1;
    letter-spacing: $base-letter-spacing;
    line-height: 50px;
    @include respond-to("desktop-lg") {
      font-size: $font-heading-lg-h1;
    }
  }
  
  h2 {
    font-size: $font-heading-h2;
    letter-spacing: $base-letter-spacing;
    line-height: 54px;
    @include respond-to("desktop-lg") {
      font-size: $font-heading-lg-h2;
    }
  }
  
  h3 {
    font-size: $font-heading-h3;
    letter-spacing: $base-letter-spacing;
    font-weight: $font-weight-b;
    line-height: 33px;
    @include respond-to("desktop-lg") {
      font-size: $font-heading-lg-h3;
    }
  }
  
  h4 {
    font-size: $font-heading-h4;
    letter-spacing: $base-letter-spacing;
    @include respond-to("desktop-lg") {
      font-size: $font-heading-lg-h5;
    }
  }
  
  h5 {
    font-size: $font-base;
    letter-spacing: $base-letter-spacing;
    line-height: $line-height-base;
    @include respond-to("desktop-lg") {
      font-size: $font-heading-lg-h5;
    }
  }
  h6 {
   
    font-size: 10px;
    letter-spacing: -0.96px;
    line-height: 23px;
    font-weight: 500;
  }
  
  p {
    font-size: $font-base;
    line-height: $line-height-base;
    font-weight: normal;
    margin: 0;
    margin-bottom: 0px;
    @include respond-to("desktop-lg") {
      font-size: $font-base-lg;
    }
  }
  
  li {
    list-style: none;
  }
  
  label {
    font-style: normal;
    font-weight: $font-weight-s;
    font-size: $font-base;
    line-height: $line-height-base;
    letter-spacing: $base-letter-spacing;
    margin: 0px;
    letter-spacing: $base-letter-spacing;
    text-align: left ;
    justify-content: left ;
    float: left;
    @include respond-to("desktop-lg") {
      font-size: $font-base-lg;
    }
  }
  
  input {
    background: $color-white ;
    box-shadow: 0px 0px 15px rgba(74, 74, 74, 0.1);
    border: none ;
    color: $color-black ;
    border-radius: $border-radius-base;
    box-sizing: border-box;
    @include border-radius($border-radius-base);
    height: $base-height ;
    margin: 0px;
    padding: 0px;
    padding-left: 1rem;
    font-size: $font-small;
    @include respond-to("desktop-lg") {
      height: $base-height-lg;
      font-size: $font-base-lg;
    }
    @include respond-to("desktop-md") {
      height: $base-height-md;
    }
  }
  .react-datepicker__input-container input {
    margin-right: 70px;
    width: 5rem;
    background: $color-white ;
    box-shadow: 0px 0px 15px rgba(74, 74, 74, 0.1);
    border: none ;
    color: $color-black ;
    border-radius: $border-radius-base;
    box-sizing: border-box;
    @include border-radius($border-radius-base);
    height: $base-height ;
    // margin: 0px;
    padding: 0px;
    font-size: $font-small;
    @include respond-to("desktop-lg") {
      height: $base-height-lg;
      font-size: $font-base-lg;
    }
    @include respond-to("desktop-md") {
      height: $base-height-md;
    }
  }
  
  input::placeholder,
  textarea::placeholder {
    color: $placeholder;
    font-size: $font-small;
    @include respond-to("desktop-lg") {
      font-size: $font-base-lg;
    }
  }
  
  textarea {
    box-shadow: 0px 0px 15px rgba(74, 74, 74, 0.1);
    border: none ;
    color: $color-black ;
    border-radius: $border-radius-base;
    box-sizing: border-box;
    @include border-radius($border-radius-base);
    margin: 0px;
    padding: 0px;
  }
  
  select {
    background: $color-white ;
    box-shadow: 0px 0px 15px rgba(74, 74, 74, 0.1);
    border: none;
    box-sizing: border-box;
    @include border-radius($border-radius-base);
    height: $base-height;
    width: 100%;
    color: $color-black ;
    margin: 0px;
    padding: 0px;
    padding-left: 16px;
    @include respond-to("desktop-lg") {
      height: $base-height-lg;
      font-size: $font-base-lg;
    }
    @include respond-to("desktop-md") {
      height: $base-height-md;
    }
  }
  select.Goal,
  input.Goal {
    border: 1px solid $color-secondary ;
  }
  button,
  .Blue-Tango-button {
    background: $color-secondary;
    @include border-radius($border-radius-base);
    height: $base-height;
    font-style: normal;
    font-weight: $font-weight-m;
    font-size: $font-base;
    text-align: center;
    letter-spacing: $base-letter-spacing;
    color: $color-white;
    outline: none;
    border: none;
    margin: 0px;
    margin-bottom: 0;
    box-shadow: 0px 0px 15px rgba(74, 74, 74, 0.1);
    width: 323px;
    cursor: pointer;
    @include respond-to("desktop-lg") {
      height: $base-height-lg;
      font-size: $font-base-lg;
      width: 300px;
    }
    @include respond-to("desktop-md") {
      height: $base-height-md;
      width: 250px;
    }
  }
  .btn {
    font-size: $font-base;
    height: $base-height;
    cursor: pointer;
    @include respond-to("desktop-lg") {
      height: $base-height-lg;
      font-size: $font-base-lg;
    }
    @include respond-to("desktop-md") {
      height: $base-height-md;
    }
  }
  button {
    &:focus {
      outline: none;
    }
  }
  .btn-blue {
    button {
      background: $sky-blue;
      @include border-radius($border-radius-base);
      height: $base-height;
      font-style: normal;
      font-weight: $font-weight-m;
      font-size: $font-base;
      text-align: center;
      letter-spacing: $base-letter-spacing;
      color: $color-white;
      outline: none;
      border: none;
      margin: 0px;
      margin-bottom: 0;
      box-shadow: 0px 0px 15px rgba(74, 74, 74, 0.1);
      width: 323px;
      cursor: pointer;
      @include respond-to("desktop-lg") {
        height: $base-height-lg;
        font-size: $font-base-lg;
        width: 300px;
      }
      @include respond-to("desktop-md") {
        height: $base-height-md;
        width: 250px;
      }
    }
  }
  .add-goal {
    border: 1px dotted $color-secondary;
    background: transparent;
    color: $color-black;
  }
  .form-group {
    margin-bottom: $base-extra-small-margin;
  }
  .form-control {
    border-radius: $border-radius-base;
  }
  //.............................custom checkbox style..................................//
  .styled-checkbox {
    position: absolute;
    opacity: 0;
  
    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      display: flex;
      align-items: center;
      color: $color-gray-secondary;
      font-size: $font-extra-small;
    }
    // Box.
    & + label:before {
      content: "";
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 15px;
      height: 15px;
      background: transparent;
      border: 1px solid $color-secondary;
    }
  
    // Box checked
    &:checked + label:before {
      background: #f35429;
    }
    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }
  
    &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }
  }
  .unstyled {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      margin: $margin-8 0;
      display: inline-block;
    }
  }
  .centered {
    width: 300px;
    margin: auto;
    text-align: center;
  }
  // hr line
  .border-pink {
    border-top: 2px solid $color-secondary;
    width: 35%;
    margin: 0px auto;
  }
  .border-pink.Qualitative {
    border-top: 1px solid $color-secondary;
    width: 43%;
    margin: 0px auto;
  }
  // comman width container
  .comman-width-container {
    margin: 0 70px 0 70px ;
    @include respond-to("desktop-lg") {
      margin: 0 40px 0px 40px ;
    }
    @include respond-to("desktop-md") {
      margin: 0 20px 0px 20px ;
    }
    @include respond-to("desktop-sm") {
      margin: 0 10px 0px 10px ;
    }
    .form-group {
      margin-bottom: 12px ;
    }
  }
  // panel comman navbar style
  .tabbable-panel {
    .nav.nav-tabs {
      justify-content: space-around;
      // margin: $base-small-margin 0 $base-small-margin 0;
      background: $color-white;
      display: flex;
      align-items: center;
      border: none;
      border-radius: $border-radius-base;
      width: 100%;
      li {
        a {
          font-size: $font-small;
          line-height: $line-height-small;
          color: $color-gray;
          text-decoration: none;
        }
      }
      li.active {
        background: $sky-blue;
        border-radius: $border-radius-base;
        padding: $padding;
        z-index: 5;
        a {
          color: $color-white;
        }
      }
    }
  }
  
  .bg-color {
    background: #f5f6fa;
  }
  // comman navbar-header style
  .navbar.navbar-expand-lg.navbar-light {
    background: $color-secondary;
    height: 60px;
    margin-top: 0px;
    margin-bottom: 0px;
    @include respond-to("desktop-md") {
      height: 100%;
      button {
        width: 55px;
      }
    }
    li {
      a {
        color: $color-white;
        font-size: $font-large;
      }
    }
    li.active {
      @include respond-to("desktop-md") {
        height: 100%;
        display: block;
      }
    }
  }
  .nav.nav-tabs {
    li.active {
      background: $color-primary;
      border-radius: $border-radius-base;
      padding: $base-extra-small-padding;
      margin: -3px -$padding-7 0px -$padding-7;
      @include respond-to("desktop-md") {
        padding: $base-extra-small-padding $padding-6 $base-extra-small-padding
          $padding-6;
      }
    }
  }
  
  // //////////////////////////////
  .navbar-header {
    background: $color-secondary;
    .nav.nav-tabs {
      background: $color-secondary;
      height: 60px;
      margin-top: 0px;
      margin-bottom: 0px;
      @include respond-to("desktop-md") {
        height: 100%;
        display: block;
        margin-top: $padding-md-5 ;
        padding-left: $padding-md-5;
      }
      li {
        a {
          color: $color-white;
          font-size: $font-large;
          @include respond-to("desktop-lg") {
            font-size: $font-large-lg;
          }
          @include respond-to("desktop-md") {
            font-size: $font-large-md;
          }
        }
      }
    }
    .nav.nav-tabs {
      li.active {
        background: $color-primary;
        border-radius: $border-radius-base;
        padding: $base-extra-small-padding;
        @include respond-to("desktop-md") {
          background: transparent;
          padding: $padding-md-7 $padding-7 15px $padding-3;
        }
      }
    }
    .buttin-right {
      @include respond-to("desktop-md") {
        margin: 0px 0px 0px auto;
      }
    }
  }
  //.....................header styles......................//
  .header-wrapper {
    height: 146px;
    background: linear-gradient(180deg, #939cff 0%, rgba(255, 255, 255, 0) 100%);
    display: flex;
    align-items: center;
    @include respond-to("desktop-md") {
      height: 100px;
    }
    @include respond-to("desktop-sm") {
      height: 60px;
    }
    .logo {
      img {
        width: auto;
        @include respond-to("desktop-md") {
          width: 200px;
        }
        @include respond-to("desktop-sm") {
          width: 100px;
        }
      }
    }
    .Employee-Team {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      p {
        font-size: $font-large;
        line-height: $line-height-large;
        font-weight: $font-weight-s;
      }
    }
    .Logout.noticount-wrap {
      position: relative;
      .noti-count {
        background-color: #ff3e3e;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        position: absolute;
        // left: 30px;
        top: -10px;
        span {
          text-align: center;
          justify-content: center;
          color: white;
          display: flex;
          align-items: center;
          padding-top: 3px;
        }
      }
    }
    .Logout.menu.noticount-wrap {
      .noti-count {
        left: 22px;
        top: 1px;
      }
    }
    .Logout {
      display: flex;
      align-items: center;
      justify-content: right;
      h3 {
        text-align: right;
        justify-content: right;
        display: inline-block;
        // width: 50%;
        cursor: pointer;
        margin-right: $base-medium-margin;
        @include respond-to("desktop-md") {
          font-size: $font-medium-lg;
          margin-right: 0px;
        }
      }
      .dropdown {
        button {
          background: none;
          width: auto;
          padding: 0px;
          box-shadow: none;
        }
        .dropdown-menu.show {
          transform: translate3d(-209px, 50px, 0px) !important;
          left: -90px ;
          background: $color-black;
          opacity: 0.85;
          box-shadow: 0px 0px 15px rgba(83, 83, 83, 0.1);
          border-radius: $border-radius-base;
          .dropdown-item {
            color: $color-light-secondary;
            font-size: $font-large;
            text-align: center;
            padding: $padding-2 0 $padding-2 0;
            width: 349px;
            border-bottom: 1px solid #878787;
          }
          .dropdown-item:last-child {
            border-bottom: none;
          }
          .dropdown-item.active,
          .dropdown-item:hover {
            color: $color-secondary;
            background: transparent;
          }
        }
      }
    }
  }
  //......................footer style......................//
  .footer-wrapper {
    background-color: $color-primary;
    .navbar-new-bottom {
      background-color: $color-primary;
      padding: 0;
      height: 104px;
      @include respond-to("desktop-sm") {
        display: none;
      }
    }
    .navbar-new-bottom ul li {
      margin-left: 3%;
      margin-right: 4%;
      margin-top: $base-medium-padding;
      margin-bottom: $base-medium-padding;
      @include respond-to("desktop-lg") {
        margin-left: 3%;
        margin-right: 3%;
      }
      @include respond-to("desktop-md") {
        margin-left: 2%;
        margin-right: 2%;
      }
    }
    .footer-hr {
      width: 90%;
      margin: 0 auto;
      background-color: white;
    }
    .All-Rights {
      padding: $padding-8 0 $padding-8 0;
      justify-content: center;
      text-align: center;
      color: $color-sky-gray;
      font-size: $font-large;
      line-height: $line-height-large;
      background: $color-primary;
      @include respond-to("desktop-sm") {
        font-size: $font-base-lg;
        padding: $padding-6 0 $padding-6 0;
      }
    }
  }
  .goallistcommonimg {
    padding: 14px;
    img {
      padding-right: 13px;
    }
    i {
      position: relative;
      top: 3px;
    }
  }
  
  .closepopup {
    position: absolute;
    top: -16px;
    right: -10px;
  }
  .spinner-border.text-primary {
    position: absolute;
    top: 45%;
    left: 50%;
  }
  
  .form-control:disabled {
    background-color: #e9ecef ;
    opacity: 1;
  }
  
  .lastempdetail {
    .form-group {
      margin-bottom: 0;
      input {
        box-shadow: none;
        border-radius: 0 ;
      }
    }
    .form-control.lastin {
      border-radius: 0px 0px 14px 14px ;
    }
  }
  
  .form-control.lasttitle {
    background: white ;
    // border-radius: 14px 14px 0px 0px ;
  }
  .rounded-circle {
    border-radius: 50% ;
    height: 50px ;
    width: 50px ;
  }
  
  // chat dropdown notification
  .chat-notification {
    position: relative;
    display: inline;
    span {
      color: white;
      position: absolute;
      background: red;
      border-radius: 50%;
      height: 25px;
      width: 25px;
      top: -2px;
      left: 10px;
    }
  }
  
  //qualititaive measurment date margin
  .start-date-span {
    display: inline-block;
    padding-top: 5px;
  }
  
  .Coaches-wrapper {
    .Coaches {
      padding: $base-padding 0px $base-padding 0px;
      @include respond-to("desktop-lg") {
        padding: $base-padding-lg 0px $base-padding-lg 0px;
      }
      @include respond-to("desktop-md") {
        padding: $base-padding-md 0px $base-padding-md 0px;
      }
      .Coaches-info {
        .Coaches-head {
          text-align: center;
          margin: 0 auto;
        }
        .heade-h2 {
          @include respond-to("desktop-md") {
            line-height: 40px;
          }
        }
        h2 {
          margin-bottom: 0px;
          font-weight: $font-weight-s;
        }
        img {
          margin-bottom: $base-extra-small-margin;
        }
        p {
          color: $placeholder;
        }
      }
      .media {
        align-items: center;
        padding-top: $base-ml-padding;
        @include respond-to("desktop-sm") {
          padding-top: $base-padding-lg;
        }
        .client-img {
          @include respond-to("desktop-sm") {
            text-align: center;
          }
          img {
            height: 180px;
            width: 200px;
          }
        }
        .media-body {
          padding-top: 15px;
          h4 {
            margin-bottom: $margin-6;
          }
          p {
            color: $placeholder;
            padding-bottom: 110px;
            border-bottom: 1px solid #c9c9c9;
            text-align: justify;
            @include respond-to("desktop-sm") {
              padding-bottom: 50px;
            }
          }
        }
      }
    }
  }
  
  .my-anchor-css-class {
    color: #ff7500;
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
  
  .content-css {
    // background-color: red;
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: #6a6a6a;
  }
  
  .my-anchor-css-class:hover {
    color: #ff7500;
    font-size: 14px;
  }
  // contact us comman style
  .contact-section {
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    @include respond-to("desktop-sm") {
      margin: $padding-8 0 $padding-8 0;
    }
    .mai-contact-wrap {
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: 308px;
      @include respond-to("desktop-md") {
        display: block;
        height: auto;
      }
      .contact-img {
        width: auto;
        box-shadow: 0px 0px 15px rgba(83, 83, 83, 0.1);
        border-radius: 14px;
        height: 100%;
        @include respond-to("desktop-sm") {
          text-align: center;
          height: auto;
        }
      }
      .contact-information {
        width: auto;
        box-shadow: 0px 0px 15px rgba(83, 83, 83, 0.1);
        border-radius: 14px;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        @include respond-to("desktop-sm") {
          height: auto;
        }
        .contact-info {
          color: black;
          font-size: 16px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          // height: 118px;
          margin-left: 10rem;
          margin-top: 4rem;
          @include respond-to("desktop-sm") {
            margin-left: 0rem;
            margin-top: 2rem;
          }
        }
      }
      .email-btn {
        font-size: 14px;
        color: black;
        color: white;
        width: 119px;
        line-height: 21px;
        margin-top: 1rem;
        box-shadow: 0px 4px 8px rgba(74, 74, 74, 0.25);
      }
    }
  }
  
  .list-of-coaches-input {
    margin-right: 0.5rem;
    max-height: 10px ;
  }
  
  @media screen and (max-width: 820px) {
    .bell img {
      margin-left: -83px;
    }
  }
  
}
