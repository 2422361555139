@import "./custom-variable1";
@import "./main1";

//.....................deshboard styles......................//

.Manager-look-wrapper {
  .manage-lock {
    background: $sky-blue;
    border-radius: $border-radius-base;
    padding: 9px 30px 9px 30px;
    margin: $base-medium-margin 70px 0 70px;
    @include respond-to("desktop-lg") {
      margin: $base-medium-margin 30px 0 30px;
    }
    @include respond-to("desktop-md") {
      margin: $base-medium-margin 0px 0 0px;
    }
    .Switch-text {
      color: $color-white;
      font-weight: $font-weight-s;
    }
    .Switch-icon {
      padding-top: 4px;
    }
  }
}
.profiles-wrapper-main {
  .profiles-wrapper {
    margin: 0 70px 0 70px;
    @include respond-to("desktop-lg") {
      margin: $base-medium-margin 30px 0px 30px;
    }
    @include respond-to("desktop-md") {
      margin: $base-medium-margin 0px 0px 0px;
    }
    .light-img-wrap {
      min-width: 661px;
      margin: 0 auto;
    }
    .profile-comman-box {
      margin-bottom: $base-ml-padding;
      margin-top: $base-margin;
    }
    .profile-comman-box-two {
      padding-bottom: $base-larg-padding;
    }

    .comman-width {
      min-width: 661px;
      margin-right: 0px;
      padding-right: 0px;
    }
    .comman-width.one {
      margin: 0 auto;
    }
    .profiles.smail {
      background-image: url(../assets/images/smail-bg-two.png);
      background-repeat: no-repeat;
      width: 100%;
      background-position: center;
      padding: $padding-8 92px;
      border-radius: $border-radius-base;
      height: 180px;
      p {
        font-size: $font-small;
        line-height: $line-height-small;
        color: $color-white;
      }
      .feeling-react {
        .react-img {
          text-align: center;
          justify-content: center;
        }
      }
      .Energy-level-react {
        .Energy-level-text {
          display: flex;
          align-items: center;
          padding-top: 25px;
          .Energy-text {
            h5 {
              color: $color-white;
            }
          }
        }
        .Energy-level-img {
          float: right;
          padding-top: 14px;
          img {
            width: 117px;
          }
        }
      }
    }
    .profiles.Achievements {
      background-image: url(../assets/images/Achievements.png);
      background-repeat: no-repeat;
      width: 100%;
      background-position: center;
      padding: $base-small-padding;
      border-radius: $border-radius-base;
      height: 184px;
      justify-content: center;
      text-align: center;
      img {
        margin-bottom: $margin-4;
      }
      p {
        color: $color-white;
      }
    }

    .profiles.sun {
      background-image: url(../assets/images/sun-bg.png);
      background-repeat: no-repeat;
      width: 458px;
      background-position: center;
      padding: $base-small-padding;
      border-radius: $border-radius-base;
      margin: 0 auto;
      height: 184px;
      img {
        width: 100%;
      }
      h5 {
        padding-bottom: 5px;
        color: $color-white;
      }
      p {
        color: $color-white;
      }
    }
    .profiles.book {
      background-image: url(../assets/images/book-bg.png);
      background-repeat: no-repeat;
      width: 100%;
      background-position: center;
      padding: $base-small-padding;
      border-radius: $border-radius-base;
      height: 184px;
      h5 {
        color: $color-white;
        font-weight: $font-weight-s;
        padding-bottom: $padding-5;
      }
      p {
        color: $color-white;
        width: 75%;
      }
    }
    .main-profiles {
      background: $color-white;
      box-shadow: 0px 0px 15px rgba(83, 83, 83, 0.1);
      border-radius: $border-radius-base;
      padding: $base-medium-padding;
      position: relative;
      .profile-img {
        position: absolute;
        top: -35px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .notification-wrap {
      .light-img {
        // background-color: red;
        text-align: center;
        margin: 0 auto;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        img {
          cursor: pointer;
          padding-right: 30px;
        }
        img:last-child {
          padding-right: 0px;
        }
        img:nth-child(2) {
          padding-right: 0px;
        }
        .mogi-react {
          background: #ef7d38;
          border-radius: 50%;
          width: 84px;
          height: 84px;
          align-items: center;
          display: flex;
          justify-content: center;
        }
        .big-hand {
          margin-left: 20px;
        }
        .whistle-icon {
          padding: 0 !important;
        }
      }
      .light-img-sms {
        text-align: right;
        justify-content: center;
        img {
          padding-right: $base-extra-small-padding;
          cursor: pointer;
        }
      }
    }
  }
}
.Goals-Stats-wrapper {
  background: $color-gray-primary;
  .Goals-Stats {
    margin: 0 70px 0 70px;
    @include respond-to("desktop-lg") {
      margin: 0 30px 0 30px;
    }
    @include respond-to("desktop-md") {
      margin: 0px;
    }
    .heding {
      padding: $base-ml-padding $base-ml-padding;
      h2 {
        font-weight: $font-weight-s;
        color: $placeholder;
        text-align: center;
        margin: 0px;
      }

      h4 {
        font-weight: $font-weight-s;
        color: #ff7500;
        text-align: center;
        margin: 0px;
      }
    }
    .Goals-Stats-details {
      display: flex;
      padding-bottom: $base-larg-padding;
      .Goal-title {
        margin-top: 0.8rem;
        background: $color-white;
        box-shadow: 0px 0px 15px rgba(83, 83, 83, 0.1);
        border-radius: $border-radius-base;
        padding: $base-medium-padding;
        margin-left: 178px;
        width: 100%;
        height: 340px;
        @include respond-to("desktop-lg") {
          margin-left: 100px;
        }
        @include respond-to("desktop-md") {
          margin-left: 60px;
        }
        span {
          margin-left: 15px;
        }
        p {
          margin-bottom: $base-small-padding;
        }
      }
    }
  }
}
.modal-dialog.thoughtmodal {
  .modal-content {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    position: relative;
    top: 150px;
    .modal-body {
      .cross {
        position: absolute;
        top: -5px;
        right: -10px;
        z-index: 99;
      }
    }
  }
}
.Qualitative-wrapper {
  background: $color-white;
  .Qualitative {
    margin: 0 70px 0 70px;
    @include respond-to("desktop-lg") {
      margin: 0 30px 0 30px;
    }
    @include respond-to("desktop-md") {
      margin: 0px;
    }
    .heding {
      padding: $base-ml-padding $base-ml-padding;
      h2 {
        font-weight: $font-weight-s;
        color: $placeholder;
        text-align: center;
        margin: 0px;
      }
      h4 {
        font-weight: $font-weight-s;
        color: #ff7500;
        text-align: center;
        margin: 0px;
      }
    }
    .Qualitative-details {
      background: $color-white;
      box-shadow: 0px 0px 15px rgba(83, 83, 83, 0.1);
      border-radius: $border-radius-base;
      .Qualitative-row {
        padding: $base-small-padding $base-medium-padding $base-small-padding
          $base-medium-padding;
        border-bottom: 1px solid $color-border;
      }
      .Qualitative-row:last-child {
        border-bottom: none;
      }
      .Qualitative-name {
        p {
          font-weight: $font-weight-s;
          font-size: $font-medium;
          line-height: $line-height-medium;
        }
      }
      .Qualitative-star {
        img {
          padding-left: 5px;
          width: 29px;
          @include respond-to("desktop-lg") {
            width: 22px;
          }
        }
      }
      .Qualitative-message {
        text-align: center;
        img {
          width: auto;
          @include respond-to("desktop-lg") {
            width: 22px;
          }
        }
      }
      .view-details {
        float: right;
        p {
          a {
            color: $color-secondary;
          }
        }
      }
    }
  }
  .Consultation-button {
    text-align: center;
    margin: $base-larg-margin 0px $base-larg-margin 0px;
    button {
      color: $color-white;
      font-weight: $font-weight-s;
      width: 223px;
      border: 1px solid #fc4f03;
      box-sizing: border-box;
      filter: drop-shadow(0px 4px 8px rgba(74, 74, 74, 0.25));
    }
  }
}

// search style
.search-wrapper {
  .search {
    margin: $base-extra-small-margin 70px 0 70px;
    position: relative;
    @include respond-to("desktop-lg") {
      margin: $base-medium-margin 30px 0 30px;
    }
    @include respond-to("desktop-md") {
      margin: $base-medium-margin 0px 0 0px;
    }
    .form-group {
      margin-bottom: $base-medium-padding;
    }
    input {
      padding-left: 50px;
      height: $base-small-height;
    }
    img {
      position: absolute;
      top: 14px;
      left: 17px;
    }
  }
}

// Feeling style
.Feeling-wrapper {
  .Feeling {
    background: $color-secondary;
    margin: $base-extra-small-margin 70px 0 70px;
    padding: $base-extra-small-padding;
    box-shadow: 0px 4px 8px rgba(74, 74, 74, 0.25);
    border-radius: $border-radius-medium;
    margin-bottom: 12px;
    @include respond-to("desktop-lg") {
      margin: 0 30px 12px 30px;
    }
    @include respond-to("desktop-md") {
      margin: 0 0px 12px 0px;
    }
    p {
      color: $color-white;
      font-weight: $font-weight-s;
      span {
        img {
          margin-top: -17px;
        }
      }
    }
  }
  .Feeling.Deshboard {
    background: $color-primary;
    box-shadow: 0px 4px 8px rgba(74, 74, 74, 0.25);
  }
}

/* Manager-Team */

.manager-team-wrapper {
  .manage-details.two {
    background: linear-gradient(
      92.66deg,
      #2786e2 0.69%,
      #a2d1ff 61.95%,
      #2786e2 102.96%
    );
  }
  .manage-details {
    margin: 0 70px 12px 70px;
    background: linear-gradient(
      92.87deg,
      #ff7500 0.43%,
      #ffc798 72.51%,
      #ff7500 102.95%
    );
    box-shadow: 0px 0px 15px rgba(83, 83, 83, 0.1);
    border-radius: $border-radius-base;
    padding: $margin-6;
    @include respond-to("desktop-lg") {
      margin: 0 30px 12px 30px;
    }
    @include respond-to("desktop-md") {
      margin: 0 0px 12px 0px;
    }
    .media {
      align-items: center;
      .media-img {
        position: relative;
        img {
          margin-right: $margin-4;
        }
        .moji-img {
          position: absolute;
          bottom: 0px;
          right: 0px;
        }
      }
    }
    .media-body {
      p {
        color: $color-white;
        font-weight: $font-weight-s;
      }
      span {
        font-size: $font-small;
        color: $color-white;
      }
    }
    .right-text {
      text-align: right;
      position: relative;
      top: 3px;
      p {
        color: $color-white;
        font-weight: $font-weight-s;
      }
      .Rate-employee {
        background: $color-white;
        color: $color-secondary;
        height: 13px;
        width: 100px;
        padding: 0px 4px 0px 4px;
        font-size: 10px;
        img {
          margin-right: 3px;
        }
      }
    }
  }
}
.select-team-member-wrapper {
  padding: $base-extra-small-padding $base-extra-small-padding 10px
    $base-extra-small-padding;
  width: 373px;
  background: $color-white;
  box-shadow: 0px 0px 9px rgba(83, 83, 83, 0.25);
  border-radius: 8px;
  margin: 0 auto;
  margin-bottom: 32px;
  .team-member-head {
    text-align: center;
    padding-bottom: 32px;
  }
  .select-team-member-wrapper {
    padding: 0px $base-extra-small-padding 10px $base-extra-small-padding;
    width: 373px;
    background: $color-white;
    box-shadow: 0px 0px 9px rgba(83, 83, 83, 0.25);
    border-radius: 8px;
    margin: 0 auto;
    margin-bottom: 32px;
    position: absolute;
    img.cross {
      position: relative;
      right: -20px;
      top: -22px;
    }
    .team-member-head {
      text-align: center;
      padding-bottom: 32px;
    }
    .select-team-member {
      height: calc(100vh - 252px) !important;
      overflow-y: auto;
      ul {
        margin-bottom: 0px;
        li {
          // margin-bottom: 8px;
          display: flex;
          align-items: center;
          padding-bottom: 14px;
          cursor: pointer;
          span {
            font-size: 14px;
          }
          img {
            margin-right: $margin-4;
          }
        }
      }
    }
  }
}

.modal-dialog.chatmodal {
  height: 410px;
  background: none;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translateX(-50%) translateY(-50%) !important;
  .modal-content {
    background: transparent;
    border: 0;
  }
  .modal-body {
    padding: 0;
  }
}

.manager-team-wrapper {
  .manage-details.two {
    background: linear-gradient(
      92.66deg,
      #2786e2 0.69%,
      #a2d1ff 61.95%,
      #2786e2 102.96%
    );
  }
  .manage-details {
    margin: 0 70px 12px 70px;
    background: linear-gradient(
      92.87deg,
      #ff7500 0.43%,
      #ffc798 72.51%,
      #ff7500 102.95%
    );
    box-shadow: 0px 0px 15px rgba(83, 83, 83, 0.1);
    border-radius: $border-radius-base;
    padding: $margin-6;
    @include respond-to("desktop-lg") {
      margin: 0 30px 12px 30px;
    }
    @include respond-to("desktop-md") {
      margin: 0 0px 12px 0px;
    }
    .media {
      align-items: center;
      .media-img {
        position: relative;
        img {
          margin-right: $margin-4;
        }
        .moji-img {
          position: absolute;
          bottom: 0px;
          right: 0px;
        }
      }
    }
    .media-body {
      p {
        color: $color-white;
        font-weight: $font-weight-s;
        width: 180px;
      }
      span {
        font-size: $font-small;
        color: $color-white;
      }
    }

    .right-text.margin-top {
      padding-top: 12px;
      img {
        margin-right: 5px;
        background: white;
        padding: 4px;
        border-radius: 50%;
        cursor: pointer;
      }
    }
    .right-text.margin-top-m {
      padding-top: 14px;
    }
    .right-text {
      text-align: right;
      position: relative;
      top: 3px;

      p {
        color: $color-white;
        font-weight: $font-weight-s;
      }
      .Rate-employee {
        background: $color-white;
        color: $color-secondary;
        height: 13px;
        width: 100px;
        padding: 0px 4px 0px 4px;
        font-size: 10px;
        img {
          margin-right: 3px;
        }
      }
      .Department-name {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        background: $color-white;
        height: 21px;
        width: 143px;
        color: #e27474;
      }
    }
  }
}

.select-team-member-wrapper {
  padding: $base-extra-small-padding $base-extra-small-padding 10px
    $base-extra-small-padding;
  width: 373px;
  background: $color-white;
  box-shadow: 0px 0px 9px rgba(83, 83, 83, 0.25);
  border-radius: 8px;
  margin: 0 auto;
  margin-bottom: 32px;
  .team-member-head {
    text-align: center;
    padding-bottom: 32px;
  }
  .select-team-member {
    ul {
      margin-bottom: 0px;
      li {
        display: flex;
        align-items: center;
        padding-bottom: 14px;
        cursor: pointer;
        span {
          font-size: 14px;
        }
        img {
          margin-right: $margin-4;
        }
      }
    }
  }
}
.custom-selec-wrap {
  font-size: 14px;
  height: 43px;
  color: #6a6a6a;
  option {
    font-size: 14px;
    color: #6a6a6a;
  }
}
