@import "./custom-variable1";
@import "./main1";

/* my profile */
.founder-wrapper {
  .founder {
    padding: $base-padding 0px $base-padding 0px;
    @include respond-to("desktop-lg") {
      padding: $base-padding-lg 0px $base-padding-lg 0px;
    }
    @include respond-to("desktop-md") {
      padding: $base-padding-md 0px $base-padding-md 0px;
    }
    .founder-info {
      h2 {
        margin-bottom: 0px;
        font-weight: $font-weight-s;
      }
      img {
        margin-bottom: $base-extra-small-margin;
      }
      p {
        color: $placeholder;
      }
    }

    .client-img {
      text-align: center;
      h4 {
        text-align: center;
        padding-top: $padding-6;
        font-weight: $font-weight-s;
      }
    }
    .media-body {
      p {
        color: $placeholder;
      }
    }
  }
}
