//Colors//
$color-primary: #5662da;
$color-secondary: #ff7500;
$sky-blue: #2786e2;
$color-primary-bgray: #434343;
$color-white: #ffffff;
$color-black: #000000;
$color-green: #0dc014;
$placeholder: #6a6a6a;
$color-gray: #747474;
$color-dark-gray: #ebebeb;
$color-sky-gray: #a8aff9;
$color-light-gray: #f5f5f9;
$color-light-gray-bg: #e5e5e5;
$color-gray-secondary: #6a6a6a;
$color-gray-primary: #f4f7fc;
$color-border: #d6d6d6;
$color-light-secondary: #d2d2d2;

//font-size//
$font-base: 16px !default;
$font-medium: ceil(($font-base + 2px)); // ~ 18px;
$font-large: ceil(($font-base + 4px)); // ~  20px;
$font-small: ceil(($font-base - 2px)); //   14px;
$font-extra-small: ceil(($font-base - 4px)); // 12px;

// LineHeight for base

$line-height-base: ceil(($font-base + 10px)); // ~  24px;
$line-height-medium: ceil(($font-medium + 10px)); // ~  27px;
$line-height-large: ceil(($font-large + 10px)); // ~ 30px;
$line-height-small: ceil(($font-small + 10px)); // ~  21px;
$line-height-extra-small: ceil(($font-extra-small + 10px)); // ~  18px;

//Font heading
$font-heading-h1: ceil(($font-base + 24px)); //  40px;
$font-heading-h2: ceil(($font-base + 20px)); // ~  36px;
$font-heading-h3: ceil(($font-base + 4px)); // ~  20px;
$font-heading-h4: ceil(($font-base + 2px)); // ~ 18px;
$font-heading-h5: $font-base; // 16px ;
$font-heading-h6: ceil(($font-base - 2px)); //   14px;

//////// margin padding reset //////////

$mp0: 0px;

// letter spacing

$base-letter-spacing: 0.04px;

//height
$base-height: 50px !default;
$base-small-height: ceil(($base-height - 7px)); // 43px;
$base-height-large: ceil(($base-height + 16px)); //   60px;
$header-height-space: 146px;

//font-weight//

$font-weight-s: 400;
$font-weight-m: 500;
$font-weight-b: 600;
$font-weight-bld: 700;
$font-weight-bold: 900;

// border-radius status

$border-radius-base: 14px !default;
$border-radius-medium: ceil(($border-radius-base - 6px)); //    8px ;
$border-radius-small: 2px;
$border-radius-circle: 50%;

// Common Padding Variables
$baseSpace: 68px !default; //  <<<== don't use this $baseSpace variable in css file.
$base-padding: $baseSpace; //    68px;
$base-larg-padding: ceil(($base-padding + 12px)); //80
$base-ml-padding: ceil(($base-padding - 18px)); // 50;
$base-medium-padding: ceil(($base-padding - 28px)); // 40;
$base-small-padding: ceil(($base-padding - 42px)); // 26;
$base-extra-small-padding: 23px;
// Common margin Variables
$base-margin: $baseSpace; //    68px;
$base-larg-margin: ceil(($base-margin + 12px)); //80
$base-ml-margin: ceil(($base-margin - 18px)); // 50;
$base-medium-margin: ceil(($base-margin - 28px)); // 40;
$base-small-margin: ceil(($base-margin - 42px)); // 26;
$base-extra-small-margin: 23px;

// Other margin paddding variables

$otherBaseSpace: 12px !default; // <<<== don't use this $otherBaseSpace variable in css file.
$margin: $otherBaseSpace;
$margin-1: ceil(($otherBaseSpace - 8px)); // 4px;
$margin-2: ceil(($otherBaseSpace - 4px)); // 8px;
$margin-3: $otherBaseSpace; // 12px;
$margin-4: ceil(($otherBaseSpace + 4px)); // 17px;
$margin-5: ceil(($otherBaseSpace + 10px)); // 22px;
$margin-6: ceil(($otherBaseSpace - 2px)); // 10px;
$margin-7: ceil(($otherBaseSpace - 1px)); // 11px;
$margin-8: ceil(($otherBaseSpace + 8px)); // 20px;

$padding: $otherBaseSpace;
$padding-1: ceil(($otherBaseSpace - 8px)); // 4px;
$padding-2: ceil(($otherBaseSpace - 4px)); // 8px;
$padding-3: $otherBaseSpace; // 12px;
$padding-4: ceil(($otherBaseSpace + 4px)); // 17px;
$padding-5: ceil(($otherBaseSpace + 10px)); // 22px;
$padding-6: ceil(($otherBaseSpace - 2px)); // 10px;
$padding-7: ceil(($otherBaseSpace - 1px)); // 11px;
$padding-8: ceil(($otherBaseSpace + 8px)); // 20px;

/////////////////////////////////////// SMALL DECICES MEDIA QUERIES /////////////////////////////////////////////////////

// For Small desktop screen fonts

$font-base-lg: 14px !default;
$font-medium-lg: ceil(($font-base-lg + 2px)) !default; // ~ 16px;
$font-large-lg: ceil(($font-base-lg + 4px)) !default; // ~  18px;
$font-small-lg: ceil(($font-base-lg - 2px)) !default; //   12px;
$font-extra-small-lg: ceil(($font-base-lg - 4px)) !default; // 10px;

// LineHeight for base

$line-height-base-lg: ceil(($font-base-lg + 8px)) !default; // ~  22px;
$line-height-medium-lg: ceil(($font-medium-lg + 9px)) !default; // ~  25px;
$line-height-large-lg: ceil(($font-large-lg + 10px)) !default; // ~  28px;
$line-height-small-lg: ceil(($font-small-lg + 6px)) !default; // ~   18px;

$font-heading-lg-h1: 32px;
$font-heading-lg-h2: 28px;
$font-heading-lg-h3: 24px;
$font-heading-lg-h4: 20px;
$font-heading-lg-h5: 16px;
$font-heading-lg-h6: 12px;
// For Small desktop screen line-height

//height
$base-height-lg: 40px !default;
$base-small-height-lg: ceil(($base-height-lg - 5px)); // 35px;
$base-height-large-lg: ceil(($base-height-lg + 5px)); //   45px;
$header-height-space-lg: 100px;

// Common Padding Variables
$base-padding-lg: 20px;
$base-small-padding-lg: 18px;
$base-extra-small-padding-lg: 6px;
$base-medium-padding-lg: 12px;

$case-inner-space-lg: 54px;

$base-margin-lg: 20px;
$base-small-margin-lg: 18px;
$base-extra-small-margin-lg: 6px;
$base-medium-margin-lg: 12px;

$margin-lg-1: 3px;
$margin-lg-2: 8px;
$margin-lg-3: 12px;
$margin-lg-4: 16px;
$margin-lg-5: 24px;
$margin-lg-6: 10px;
$margin-lg-7: 24px;
$margin-lg-8: 18px;

$padding-lg-1: 3px;
$padding-lg-2: 8px;
$padding-lg-3: 12px;
$padding-lg-4: 16px;
$padding-lg-5: 24px;
$padding-lg-6: 28px;
$padding-lg-7: 14px;

$item-width-height-lg: 48px;
$item-small-width-height-lg: 32px;
$item-extra-small-width-height-lg: 22px;

////////////////////////MEDIA QUERIES FOR MAX-WIDTH 992PX   /////////////////////////////////

// For Small desktop screen fonts

$font-base-md: 12px !default;
$font-medium-md: ceil(($font-base-md + 2px)) !default; // ~ 14px;
$font-large-md: ceil(($font-base-md + 4px)) !default; // ~  16px;

//height
$base-height-md: 35px !default;
$base-small-height-md: ceil(($base-height-lg - 5px)); // 32px;
$base-height-large-md: ceil(($base-height-lg + 5px)); //   40px;
$header-height-space-md: 80px;

// Common Padding Variables

$base-padding-md: 16px;
$base-small-padding-md: 14px;
$base-margin-md: 16px;
$base-small-margin-md: 14px;

$margin-md-1: 3px;
$margin-md-2: 8px;
$margin-md-3: 12px;
$margin-md-4: 16px;
$margin-md-5: 20px;

$padding-md-1: 3px;
$padding-md-2: 8px;
$padding-md-3: 12px;
$padding-md-4: 15px;
$padding-md-5: 20px;
$padding-md-7: 10px;
