// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";

.table-striped tbody tr:nth-of-type(odd) {
  background-color: white !important;
}

tr:hover {
  background-color: #f5f5f5;
}


.min-height-100 {
  min-height: 100%;
}


.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  // margin-left: 46rem;
  margin-top: 3rem;
  // vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.imglogo {
  vertical-align: middle;
  border-style: none;
  width: 13rem;
  margin-top: -12px;
}

.form-control1 {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.font-size-1 {
  font-size: 1.4rem !important;
}

.My-Profile .profile-edit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.padding-17px {
  padding: 17px;
}

.checkbox {
  margin-left: 26px !important;
  box-shadow: none !important;
}

.center-center {
  text-align: center;
  justify-content: center;
}
.define-slot-main-div {
  min-height: 60vh;
  align-items: center;
  text-align: start;
  justify-content: center;
  display: grid;
}

.define-slot-maindiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.define-slot-subdiv {
  margin-top: 10%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.defineslot-title {
  padding-left: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 20.5794px;
  line-height: 31px;
  margin-top: -38px;
}

.availbility-check-wrapper .defineslot-title2 {
  margin-top: 1rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 20.5794px;
  line-height: 31px;
}

.define-slot-inputdiv {
  display: flex;
  margin-left: -3rem;
}

.availbility-check-wrapper .define-slot-input1 {
  margin-top: -10px;
  margin-left: -12rem;
  box-shadow: none;
}

.availbility-check-wrapper .define-slot-input2 {
  margin-top: -9px;
  margin-left: -13rem;
  box-shadow: none;
}

.availbility-check-wrapper .define-slot-radiobtn1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 13.657px;
  line-height: 32px;
  text-align: center;
  margin-left: 4rem;
}

.define-slot-days {
  width: 10rem;
  //   height: 10rem !important;
  margin-right: -102px;
  background-color: #f5f6fa;
}

.days-label {
  display: flex;
  align-items: center;
  padding: 20px;
}

.mainprofile {
  width: 558px;
  margin: 0 auto;
  position: relative;
}

.profile-div {
  color: grey;
  min-width: 10%;
  height: 1.5rem;
  border-top-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 5%;
  padding-top: 1px;
}

.profile-div-1 {
  color: grey;
  min-width: 10%;
  height: 1.5rem;
  border-top-left-radius: 0.6rem;
  border-bottom-right-radius: 1rem;
  position: absolute;
  top: 0;
  right: 0;
  padding-left: 5%;
}

.profile-div1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  align-items: center;
}
.profile-p {
  width: 12rem;
  word-break: break-all;
  text-align: left;
}

.imgprofile {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  border: 2px solid #2362a2;
}
.ladelstyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  align-items: center;
}

.labelstyless {
  width: 12rem;
  word-break: break-all;
  text-align: left;
}

.labelstylesss {
  // width: 12rem !important;
  word-break: break-all;
  text-align: left;
}

.viewbutton {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-top: 4%;
}

.data-main-div {
  display: flex !important;
  flex-direction: column !important;
  width: 50rem !important;
}
.editbutton {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 4%;
}

.profileimages {
  background-color: red;
  width: 110px;
  height: 110px;
  border-radius: 50%;
}
.edit {
  position: absolute;
  bottom: 12px;
  right: 12px;
}

.red {
  color: red;
}

.green {
  color: green;
}

.prev-submit {
  background-color: #0d86ff !important;
  color: white !important;
  width: 8rem !important;
}

.row .mysessionrequest-inline-reject {
  width: 30%;
  background-color: #0d86ff;
}

.hr {
  background: #ff7500 !important;
  box-shadow: 0px 0px 40.2039px rgba(74, 74, 74, 0.1) !important;
  border-radius: 200.657px !important;
  width: 158px !important;
  margin: 1px !important;
  padding: 1px !important;
}
.col .defineslot-hr {
  background: #ff7500;
  box-shadow: 0px 0px 22.0494px rgba(74, 74, 74, 0.1);
  border-radius: 40.5794px;
  padding: 2px;
  width: 134px;
}

.defineslots-row-inline {
  display: flex;
  grid-gap: 0px 0px;
  margin-top: 20px;
}

.hr-div {
  color: red;
  font-size: 0.7rem;
}

.col .hr-div2 {
  background: #ff7500;
  box-shadow: 0px 0px 22.0494px rgba(74, 74, 74, 0.1);
  border-radius: 40.5794px;
  padding: 2px;
  width: 183px;
}

.prev-date2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.prev-date3 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 2rem;
  align-items: center;
}

.admin-management-icon-div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 2rem;
  align-items: center;
}

.prev-date5 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  margin-top: 26px;
}

.prev-date6 {
  display: flex;
  flex-direction: column;
  height: 50vh;
  overflow-y: scroll;
  margin-top: -4rem;
}

.margin-bottom-4rem {
  margin-bottom: 4rem;
}

.mainprofile2 {
  width: 558px !important;
  margin: 0 auto !important;
  position: relative !important;
}

.session-history-div {
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
}
.margin-left-2rem {
  margin-left: -2rem !important;
}

.button-div {
  width: 30% !important;
  background-color: green !important;
}

.padding-right-332px {
  padding-right: 332px;
}

.modal-title {
  font-size: 1.1rem !important;
  font-weight: 600 !important;
}

.tango-div {
  position: absolute;
  top: 35%;
  left: 4rem;
}

.no-session-found {
  text-align: center !important;
  margin-top: 4rem !important;
  color: #ff6932 !important;
}

.width-93 {
  width: 93%;
}

.hr {
  background-color: #ff7500 !important;
}

.addgoal {
  border-bottom: 1px solid #c9c7c7 !important;
}

.coach-no-coach-found {
  margin-left: 40%;
}

.padding-botttom-5px {
  padding-bottom: 5px !important;
}

.color-black {
  color: black !important;
}

.layout-textarea {
  width: 100% !important ;
  background-color: white !important;
}

.layout-div-div {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.share-myprofile {
  margin-top: 2rem;
}

.Cmodal-footer {
  display: flex;
  flex-direction: row;
}

.margin-right-6rem {
  margin-right: 6rem;
}

.education-box-myprofilelist {
  display: flex;
}

.display-flex {
  display: flex;
}

.preview-slot-table {
  text-align: center;
  margin-top: -1rem;
}

.width-90 {
  width: 90%;
}

.days {
  display: flex;
  margin-right: -1rem;
  padding: 1rem;
  width: 90%;
}

.font-Size-10px {
  font-size: 10px;
}

.margin-top-33 {
  margin-top: 33px;
}

.min-height-60vh {
  min-height: 60vh;
}

.color-white {
  color: white;
}

.position-relative {
  position: relative !important;
}

.letter-spacing {
  letter-spacing: 0.04px !important;
}

.height-43px {
  height: 43px !important;
}

.font-size {
  font-size: 0.9rem !important;
}

.width-8rem {
  width: 8rem;
}

.contactus-profile-pic {
  position: relative;
  margin-bottom: 1rem;
}

.update-profile-pic {
  position: relative;
  padding-bottom: 2rem;
}

.padding-right-270px {
  padding-right: 270px;
}

.preview-defineslots-button {
  margin-bottom: 2rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}

.Blue-Tango-button {
  background: #ff7500;
  /* border-radius: 14px; */
  border-radius: 14px;
  -moz-border-radius: 14px;
  height: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.04px;
  color: #fff;
  outline: none;
  border: none;
  margin: 0;
  box-shadow: 0 0 15pxrgba (74, 74, 74, 0.1);
  width: 323px;
  cursor: pointer;
  margin-top: -3rem;
}

.preview-slot-your-session-will {
  text-align: center;
  margin-bottom: 3rem;
}

.preview-container-slots {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  margin-top: -9px;
}

.preview-slots-button-div {
  margin-bottom: 2rem;
  margin-left: 1rem;
}

.preview-slots-container5 {
  justify-content: space-around;
  display: flex;
  flex-direction: row;
  padding: 5rem;
  margin-left: -11rem;
}

.previw-slot-slot-map {
  display: flex;
  flex-direction: row;
  margin-left: 12rem;
}

.preview-slot-slots {
  height: 1rem;
  width: 1rem;
  // backgroundColor: "blue",
  border-radius: 1rem;
  text-align: center;
}

.My-Profile-wrapper .form-control {
  padding-left: 1rem;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
  width: 3rem !important;
}

.notification-img-css {
  margin-right: 0.5rem !important;
  width: 24px !important;
  height: 24px !important;
}

.Notification-main-wrap-inline-css {
  width: 638px !important;
  background: #fff !important;
  //box-shadow: 0 0 15px rgb(83 83 83 / 10%) !important;
  border-radius: 14px !important;
  margin: 0 auto !important;
  padding: 0px 70px !important;
}

.spinner-inline-css {
  left: 45% !important;
}

.notifications-bodys-inline-css {
  color: orange;
  text-align: center;
}

.notification-body-inline-css {
  height: 450px !important;
  overflow: auto !important;
  width: 31rem !important;
}

.add-role-ccard-header-inline {
  font-family: Lato;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.addrole-inline-css-div1 {
  display: flex;
  margin-bottom: 1rem;
}

.addrole-inline-css-div2 {
  width: 45%;
  margin-right: 2rem;
}

.addrole-cinput-inline-css {
  margin-right: 1rem;
}

.margin-right-1rem {
  margin-right: 1rem;
}
.addrole-inline-css-cbutton {
  max-height: 2rem;
  margin-left: 1rem;
}

.margin-top-1rem {
  margin-top: 1rem;
}

.addrole-cfrom-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  margin-left: 5rem;
  margin-top: 5rem;
}

.addrole-inline-addmin-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 4rem;
}

.add-role-admin-inline-css {
  display: flex;
  justify-content: center;
  align-items: center;
}

.addrole-admin-inline-css-div2 {
  margin-left: 1rem;
  font-size: 1.2rem;
}

.addrole-inline-css-checkbox1 {
  display: flex;
  width: 50%;
  justify-content: space-between;
}

.addrole-inline-css-checkbox2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addrole-inline-css-input {
  margin-right: 1rem;
  width: 1.2rem;
  box-shadow: none;
}

.font-size-1rem {
  font-size: 1.2rem;
}

.width-5rem {
  width: 5rem;
}

.chat-details-wrapper {
  .spinner-border {
    position: fixed;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
  }
}

.spinner-borde {
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
}

.admin-management-no-result {
  height: 14rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: #0d86ff;
}

.admin-management-table {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.admin-management-input {
  min-width: 20rem;
  margin-right: 1rem;
  min-height: 2.41rem;
  border-radius: 5px;
}

.margin-left-1rem {
  margin-left: 1rem;
}

.admin-management-badget {
  width: 4rem;
  height: 1.1rem;
}

.admin-management-cion-trash {
  color: red !important;
  cursor: pointer;
  outline: none;
  box-shadow: none;
}

.cursor-pointer {
  cursor: pointer;
}

.color-0d86ff {
  color: #0d86ff;
}

.admin-management-ccardheader {
  font-family: Lato;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-1rem {
  display: flex;
  margin-bottom: 1rem;
}

.width-45 {
  width: 45%;
}

.addcoachbios-footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.height14rem {
  height: 14rem;
}

.width20rem {
  width: 20rem;
}
    .admin-management-table   .add-button {
  width: auto;
  margin-left: auto;
}

.margin8left-8width {
  margin-left: 4rem;
  width: 8rem;
}

.coachavilabilityDataBody {
  height: 14rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: #0d86ff;
}

.fontFamilyPoppins {
  font-family: Poppins;
}

.height3rem {
  height: 3rem;
}

.height4rem {
  height: 4rem;
}

.coach-bios {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 2rem;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.minHeightMarginright {
  min-width: 17rem;
  margin-right: 1rem;
  min-height: 2.41rem;
  border-radius: 5px;
}

.flex-flexStart {
  display: flex !important;
  justify-content: flex-start !important;
}

.width-30rem {
  width: 30rem;
}

.width-60rem {
  width: 60rem;
}
.width30-marginRight {
  width: 30rem;
  margin-right: 2rem;
}
.marginTop-flex-justifyContent {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
.flex-spaceBet {
  display: flex;
  justify-content: space-between;
}

.flex-dir {
  width: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.wid5-height1 {
  width: 5rem;
  height: 1.1rem;
}

.margin-left1_1 {
  margin-left: 1rem;
}

.session-div-button {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-between;
  align-items: "center";
  margin-bottom: 1rem;
}

.lato {
  font-family: Lato;
}

.editprofile {
  display: flex;
  justify-content: center;
  align-items: center;
}

.zindex {
  z-index: 0;
}

.background-color {
  background-color: blue !important;
}

.margin-bottom {
  margin-bottom: 2rem;
}

.height-width-7ren {
  width: 7rem;
  height: 7rem;
}

.flex-flexStart-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.background-color-none {
  background-color: none;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-18 {
  font-size: 18px;
}

.border-none {
  border: none;
}
.margin-left-9-5 {
  margin-left: 9.5rem;
}

.margin-left-12-5 {
  margin-left: 12.5rem;
}
margin-left-8 {
  margin-left: 8rem;
}

.flex-direction-row {
  flex-direction: row;
}

.flex-col-center {
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
}

.tcmodal {
  background: #ffffff !important;
  box-shadow: 0px 0px 11.7947px rgba(83, 83, 83, 0.25) !important;
  border-radius: 10.4842px !important;
}

.viewcoach-inline-css {
  margin-bottom: 2rem;
  width: 6rem;
  height: 6rem;
  margin-left: 40%;
}

.view-coach-clabel {
  width: 16rem;
  color: grey;
}
.view-coach-clabel1 {
  width: 18rem;
  color: grey;
}

.view-coach-clabel2 {
  width: 20rem;
  color: grey;
}

.view-coach-span {
  margin-left: 10px;
  margin-bottom: 5px;
}

.errmsg {
  color: red;
}

.preview-slot {
  text-align: center;
  .spinner-border {
    margin: 0 auto;
  }

  .form-group {
    text-align: left;
  }
}




.left-0{
  left:-90px !important;
}