@import "./custom-variable1";
@import "./main1";

.availbility-check-wrapper {
  .calendar-box {
    background: #e8e8e8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 14px;
    width: 371px;
    @include respond-to("desktop-md") {
      width: 100%;
    }
    h2 {
      background: rgba(24, 91, 155, 0.5);
      border-radius: 0px 14px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 33px;
      text-align: center;
      color: #ffffff;
      width: 160px;
    }
    select {
      background: none;
      box-shadow: inherit;
      border: 0;
      border-bottom: 2px solid #ff7500;
      border-radius: inherit;
      font-family: "Poppins";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #6a6a6a;
    }
    img {
      width: 100%;
    }
  }
  .h2 {
    font-style: normal;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    color: #1a1a1a;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 20px;
  }
  .content-box {
    color: #6a6a6a;
    font-family: "Poppins";
    h5 {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      margin: 0px 0 27px 0;
      @include respond-to("desktop-md") {
        margin-top: 40px;
      }
    }
    .calender-content {
      margin-bottom: 50px;
      .date {
        width: 100px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
      }
      .description {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .border-line {
          height: 1px;
          background-color: #b0b0b0;
          width: 100%;
        }
        .content {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
          width: 100%;
          text-align: left;
          padding: 5px;
          color: white;
          margin-bottom: 10px;
        }
        .green-box {
          background: #49d625;
        }
        .red-box {
          background: #ff3434;
        }
      }
    }
  }
  .button-box {
    margin-top: 40px;
    padding-bottom: 60px;
    text-align: center;
    @include respond-to("desktop-md") {
      margin-top: 0px;
      padding-bottom: 40px;
    }
  }
}
